<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h1 class="pb-5">Add new cost domain</h1>
      </div>
      <div class="cell small-8 medium-2">
        <router-link
          to="/admin/cost-domains/">
          <button class="button expanded">
            All Cost Domains
          </button>
        </router-link>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <form @submit.prevent="save">
          <label>Cost Domain
            <input
              aria-describedby="domainHelpText"
              name="domain"
              placeholder="Domain"
              type="text"
              v-model="domain">
          </label>
          <p
            class="help-text"
            id="domainHelpText">
            Please enter cost domain. I.e. Creative Services
          </p>
          <label>Fee/Expense</label>
          <select v-model="fee">
            <option value="true">Fee</option>
            <option value="false">Expense</option>
          </select>
          <div class="pt-3">
            <button class="button">
              Save Domain
            </button>
          </div>
        </form>
      </div>
    </div>
    <app-message
      v-if="showMessage === true"
      :messageText="message"
      :link="link"
      :linkText="linkText"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Message from '@/components/messages/Message.vue';
import axios from '../../../axios';

export default {
  name: 'CostDomainsCreate',
  components: {
    appMessage: Message,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      domain: '',
      fee: true,
      link: '/admin/cost-domains',
      linkText: 'View all cost domains',
      message: '',
      showMessage: false,
    };
  },
  methods: {
    save() {
      const postData = {};
      postData.domain = this.domain;
      postData.fee = this.fee;
      axios.post(`/costDomains/create.json?token=${this.token}`, postData)
        .then((response) => {
          this.message = response.data.message;
          this.showMessage = true;
          this.domain = '';
        })
        .catch((error) => {
          console.log(error);
          this.message = 'Sorry the domain was not created. Please try again.';
          this.showMessage = true;
        });
    },
  },
};
</script>
